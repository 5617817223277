(function () {
    'use strict';

    angular.module('informaApp')
        .component('summaryElement', {
            templateUrl: 'components/summary-view/summary-elements/template.html',
            controller: SummaryElement,
            bindings: {
                element: '<',
                data: '<',
                tableGroupOptions: '<',
                hiddenDrugs: '<'
            }
        });

    function SummaryElement(SummaryChartTypes) {
        this.chartTypes = SummaryChartTypes;

        this.isLoading = true;

        this.onReady = () => {
            this.isLoading = false;
        };

        this.onStartRedraw = () => {
            this.isLoading = true;
        };
    }
})();